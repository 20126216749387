@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: 'Lato', sans-serif;
  position: relative;
}

.checkbox:checked::after {
  content: '✓';
  display: block;
  width: 1rem;
  height: 1rem;
  color: white;
  position: relative;
  top: -0.1rem;
  left: 0.3rem;
}

li.pagination-active,
li.pagination-active > a {
  @apply bg-primary text-white;
}

li.disabled-pagination-link,
li.disabled-pagination-link > a {
  @apply text-gray-900 bg-slate-200;
}

/* DatePickerStyles.css */

.react-date-picker__wrapper {
  border: none !important;
}

.react-date-picker {
  @apply bg-secondary border outline-none border-black text-gray-900 text-sm rounded-lg block w-full p-4 mt-2 focus:border-primary focus:border-2 disabled:bg-gray-300 disabled:focus:text-gray-800;
}

.react-date-picker__wrapper {
  @apply flex items-center;
}

.react-date-picker__inputGroup {
  @apply flex items-center space-x-2;
}

.react-date-picker__clear-button,
.react-date-picker__calendar-button {
  @apply text-gray-600 hover:text-gray-900;
}

.react-date-picker__calendar {
  @apply bg-white border border-gray-300 rounded-md shadow-md p-2;
}

.react-calendar {
  @apply bg-white rounded-md;
}

.react-calendar__tile {
  @apply p-2 hover:bg-blue-500 hover:text-white;
}

.react-calendar__tile--active {
  @apply bg-blue-500 text-white;
}

.react-calendar__navigation__label {
  @apply text-center font-semibold;
}

.react-calendar__navigation__arrow {
  @apply text-gray-600 hover:text-gray-900;
}
